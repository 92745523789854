<template>
  <v-dialog v-if="product" v-model="value" persistent scrollable max-width="70vw">
    <v-card >
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{variant}} {{sku}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title class="mb-4">
        {{product.artwork.title}} by {{product.artwork.artist.name}}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="5">
            <v-img width="300" height="300" class="mt-2" style="border: 1px solid silver" contain :src="artworkImage" ripple />
          </v-col>
          <v-col cols="7">
            <v-autocomplete
              class="mt-2"
              v-model="product.exclusiveTo"
              :items="customers"
              outlined
              clearable
              clear-icon="mdi-close-box"
              dense
              item-text="customerName"
              item-value="accountNumber"
              label="Exclusive To"
              :loading="customersLoading"
              @change="isDirty=true"
            >
            </v-autocomplete>
            <v-form ref="formbase" v-model="formValid" lazy-validation @submit.prevent>
              <v-form-base v-if="product" :row="{ noGutters:false }"  :col="{cols:12, sm:12, md:12 }" :model="product" :schema="schema" @input="handleEvent" />
            </v-form>
          </v-col>
        </v-row>
        <v-card-title v-if="images.length">Product Images. Drag to re-order</v-card-title>
        <draggable :list="images" class="d-flex flex-wrap" @end="isDirty = true">
          <v-card  v-for="(image, index) in images" :key="image.uuid" class="pa-4 ma-2">
            <v-icon style="position: absolute; top: 0; right: 0;" @click="onRemove(index)">mdi-delete</v-icon>
            <v-img width="300" height="300" contain class="ma-2" :src="image.dataURL" ripple />
          </v-card>
        </draggable>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!isDirty" color="success" class="mr-4" @click.native="saveProduct()">
          Save
        </v-btn>
        <!-- <v-btn color="blue darken-1" text @click.stop="downloadGreetingCard(product.artworkImage)">Download Press Ready Artwork</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductApi from '@/api/admin/product'
import ProductTypeApi from '@/api/productTypes'
import ProductTypeVariantsApi from '@/api/productTypeVariants'
import UserApi from '@/api/user'
import ArtworkApi from '@/api/artwork'
import vFormBase from '@/components/vFormBase'
import regions from '@/constants/regions'
import subjectMatter from '@/constants/subjectMatter'
import S3Api from '@/api/s3'
import axios from 'axios'
import draggable from 'vuedraggable'
import { resizeImage, readFileAsDataURL, dataURLtoFile, fetchBlob } from '@/utilities/imageUtilities'
import FormSchemas from '@/forms/schemas/'
import parseSku from '@/utilities/parseSku'
import { mapState } from 'vuex'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/artwork`

export default {
  props: {
    value: Boolean,
    edit: Boolean,
    sku: String,
    variant: String
  },
  components: {
    vFormBase,
    draggable
  },
  async mounted() {
  },
  data() {
    return {
      product: null,
      customers: [],
      customersLoading: false,
      exclusiveTo: null,
      images: [],
      imagesToDelete: [],
      imagesToUpload: [],
      imgUrl: process.env.VUE_APP_IMG_URL,
      artworkImage: null,
      isDirty: false,
      formValid: true,
      rules: {
        required: (value) => !!value || 'Required.'
      },
    }
  },
  computed: mapState({
    productTypes: (state) => state.products.productTypes,
    productTypeVariants: (state) => state.products.productTypeVariants,
    schema() {
      switch (this.product.productTypeCode) {
        case "GICL": {
          return FormSchemas.productSchemaGICL([], this.rules, true)
          break
        }
        case "GICO": {
          return FormSchemas.productSchemaGICO([], this.rules, true)
          break
        }
        default: {
          return FormSchemas.productSchema([], this.rules, true)
          break
        }
      }
    },
  }),
  async created() {
    this.customersLoading = true
    UserApi.getUsers().then((results) => {
      this.customers = results.data
      this.customersLoading = false
    }),
    ProductApi.getProductBySKU({sku: this.sku})
    .then( async res => {
      this.product = res.data
      this.artworkImage = `${this.imgUrl}/300px/${this.product.artwork.image}`
      for (const image of this.product.productImages) {
        const dataURL = await fetchBlob( `300px/${image}`)
        this.images.push({name: image, dataURL: dataURL})
      }
    }).catch(
      err => {
        console.log('err', err)
        this.loadingData = false
      }
    )
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async onRemove(img) {
      this.imagesToDelete.push(this.images[img])
      this.images.splice(img,1)
      this.isDirty = true
    },
    onEnd(evt) {
      this.isDirty = true
    },
    async handleEvent(evt) {
      this.isDirty = true
      this.$refs.formbase.validate()
      switch (evt.key) {
        case 'image' : {
          const _images = this.product.image
          for (const img in _images) {
            const uuid = crypto.randomUUID()
            const b64str = await readFileAsDataURL(_images[img]);
             this.images.push({ name: `${this.product.sku}-${uuid}.jpg` , dataURL: b64str })
             this.imagesToUpload.push({ name: `${this.product.sku}-${uuid}.jpg` , dataURL: b64str })
          }
          this.product.image = []
          this.dirty = true
          break
        }
        default: console.log(evt)
      }
    },
    downloadGreetingCard(image) {
      ProductApi.getGreetingCardPDF(image).then((results) => {
      })
    },
    async saveProduct() {
      this.loadingData = true
      ProductApi.updateProduct({
        ...this.product,
        productImages: this.images.map(item => item.name),
        productImage: this.images.map(item => item.name)[0]
        }).then( async (results) => {
        // this.product = {...results.data }
        for (const image of this.imagesToDelete) {
          await S3Api.deleteObject(`100px/${image.name}`)
          await S3Api.deleteObject(`300px/${image.name}`)
          await S3Api.deleteObject(`1500px/${image.name}`)
        }
        for (const image of this.imagesToUpload) {
          const _IMG1500 = await resizeImage(image.dataURL, { maxHeight: 1500, maxWidth: 1500 } );
          const _IMG300 = await resizeImage(image.dataURL, { maxHeight: 300, maxWidth: 300 } );
          const _IMG100 = await resizeImage(image.dataURL, { maxHeight: 100, maxWidth: 100 } );
          ArtworkApi.uploadArtworkImage( { path: '1500px', name: `${image.name}`, file: dataURLtoFile(_IMG1500.url) })
          ArtworkApi.uploadArtworkImage( { path: '300px', name: `${image.name}`, file: dataURLtoFile(_IMG300.url) })
          ArtworkApi.uploadArtworkImage( { path: '100px', name: `${image.name}`, file: dataURLtoFile(_IMG100.url) })
        }
        this.imagesToDelete = []
        this.imagesToUpload = []
        this.loadingData = false
        this.close()
      }).catch(
        err => {
          console.log('err', err)
          this.loadingData = false
        }
      )
      this.isDirty = false
    },
    log(v){
      // this.variant.productTypeVariantCode && this.variant.productTypeVariantCode.toUpperCase()
      this.isDirty = true
      this.formValid = this.$refs.formbase.validate()
    }
  }
}
</script>

